import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormValuePipe } from './reactive-form-value.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [ReactiveFormValuePipe],
    exports:[ReactiveFormValuePipe]
})
export class SharedPipesModule { }
