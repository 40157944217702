
import {map} from 'rxjs/operators';


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, Resolve, RouterStateSnapshot,
  ActivatedRouteSnapshot } from '@angular/router';


@Injectable()
export class SignInResolver implements Resolve<any> {
  constructor( private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const authenticated = route.paramMap.get('authenticated');
    const authToken = localStorage.getItem('refresh_token');
    const accessToken = localStorage.getItem('access_token');
    const dashboard = localStorage.getItem('dashboard');
    if (authToken && accessToken && dashboard == 'medical') {
        this.router.navigate( ['/doctor_onboarding']);
    } else {
       return null;
    }
  }
}
