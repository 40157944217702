import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { ConstantsService } from "./constants.service";
import { HttpServiceService } from "./http-service.service";
import { ToastrService } from "ngx-toastr";
import {
    DomSanitizer,
    SafeResourceUrl,
    SafeUrl,
} from "@angular/platform-browser";
import {
    NgbActiveModal,
    NgbModal,
    NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common";

@Injectable()
export class UtilService {

    modalOption: NgbModalOptions = {};
    today = new Date();
    constructor(
        private http: HttpServiceService,
        private constants: ConstantsService,
        private toastr: ToastrService,
        private sanitizer: DomSanitizer,
        private modalService: NgbModal,
        private datePipe: DatePipe
    ) {
        this.modalOption.backdrop = "static";
        this.modalOption.keyboard = false;
    }

    downloadFile(fileURL, fileName) {
        if (fileURL) {
            var save = document.createElement('a');
            save.href = fileURL;
            save.target = '_blank';
            save.download = fileName || 'unknown';

            var evt = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: false
            });
            save.dispatchEvent(evt);

            (window.URL || window['webkitURL']).revokeObjectURL(save.href);
        }
    }
}
