import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HrContractComponent } from './hr-contract.component';
import { HrContractRoutingModule } from './hr-contract-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [HrContractComponent],
  imports: [
    CommonModule,
    HrContractRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers:[NgbActiveModal,DatePipe]
})
export class HrContractModule { }
