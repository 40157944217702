import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from "@angular/platform-browser";

@Component({
  selector: 'iframe-popup',
  template:`<div style="text-align:center; padding: 20px; position: relative;">
              <div (click)="close()" style="position: absolute; top: 0px; right: 10px;">
                  <img id="frame" alt="closes" style="width: 15px;" src="https://firebasestorage.googleapis.com/v0/b/new-website-14c80.appspot.com/o/finance-dashboard%2Fclose%20(1)%403x.png?alt=media&token=fbdfab89-0344-4939-ac30-842ec03606c0" (click)="close()">
              </div>
              <iframe  [src]="tempUrl" width="1200px" height="600px"></iframe>
            </div>`,
  styleUrls: ['./iframePopup.scss']
})
export class IframePopup {
    @Input() data: {};
    tempUrl;
  constructor(public activeModal: NgbActiveModal, private sanitizer: DomSanitizer) {
  }
  ngOnInit() {
    // console.log("iframe", this.data)
    this.tempUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data['url']);
    // this.getTempUrl()
  }

  // async getTempUrl(){

  // }

  close(){
    this.activeModal.close()
    return
  }
}
