import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConstantsService } from 'src/app/shared/services/constants.service';
import { HttpServiceService } from 'src/app/shared/services/http-service.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { ShowNumberComponent } from '../show-number/show-number.component';
import CryptoJS from 'crypto-js';


@Component({
  selector: 'app-call-mask',
  templateUrl: './call-mask.component.html',
  styleUrls: ['./call-mask.component.scss']
})
export class CallMaskComponent implements OnInit {

  isApiCallInProgress: boolean = false
  selectedNumber: string = null
  selectedCliNumber: string = null;
  otherNumber;
  srnNumbersArr: any[] = []
  unMaskNumbersArr: string[] = []
  maskNumbersArr: string[] = []
  isNumberUnmask: boolean = false
  titleStringOfUnmaskNumber: string = ''
  source: any;
  otherNumberArr: any;
  constructor(
    public dialogRef: MatDialogRef<CallMaskComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private url: ConstantsService,
    private http: HttpServiceService,
    private snackBar: SnackBarService
  ) { }

  ngOnInit() {
    console.log(this.data);
    this.source = this.data.source;
    this.getSrnNumberApiCall(this.data.category)
    this.maskNumbersArr = this.data.fromSurgeryRisk ? [this.data.phoneNo, ...this.data.otherNumber, "Other"] : [this.data.phoneNo]
    this.otherNumberArr = this.data.otherNumber
  }

  openShowNumberModal() {
    console.log(this.data.doctorOnboarding);

    const dialogRefForShow = this.dialog.open(ShowNumberComponent, {
      panelClass: 'show-number-modal',
      data: {
        "leadId": this.data.leadId,
        "docLeadFlag": this.data.docLeadFlag,
        "source": this.source ? this.source : "Medical dashboard",
        "doctorFlag": this.data.doctorOnboarding
      }
    })
    dialogRefForShow.afterClosed().subscribe((unMaskContact: string[]) => {
      if (unMaskContact && unMaskContact.length) {
        this.unMaskNumbersArr = [...unMaskContact]
        this.maskNumbersArr = [...unMaskContact]
        this.isNumberUnmask = true
        this.titleStringOfUnmaskNumber = unMaskContact.reduce((prevVal, currVal) => {
          if (prevVal) {
            return prevVal + ',\n' + currVal
          } else {
            return currVal
          }
        }, '')
      }
    })
  }

  callOnSelected() {
    this.isApiCallInProgress = true
    let reqObj;
    if (this.data.doctorOnboarding) {
      reqObj = {
        "cliNumber": this.selectedCliNumber,
        "leadId": this.data.leadId,
        "source": "medicalDashboard",
        "module": "Pristyn Doctors",
        "doctorFlag": true,
        "callType": ""
      }
    } else {
      reqObj = {
        "leadId": this.data.leadId,
        "cliNumber": this.selectedCliNumber,
        "source": this.source ? this.source : "MedicalDashboard",
        "docLeadFlag": this.data.docLeadFlag,
        "otherNumber": this.selectedNumber == 'Other' && this.otherNumber ?
          CryptoJS.SHA256('91' + this.otherNumber).toString(CryptoJS.enc.Hex) : CryptoJS.SHA256('91' + this.selectedNumber).toString(CryptoJS.enc.Hex)
      }
    }
    console.log(reqObj);
    if (this.selectedNumber == 'Other') {
      this.addNumber();
    }

    this.http.postData(this.url.clickToCallLeads, reqObj)
      .then((res: any) => {
        if (res.status == "Success") {
          this.snackBar.success('Call will be placed shortly...')
          this.closeModal()
        } else {
          if (res.data.result.message) {
            this.snackBar.warning(res.data.result.message)
          } else {
            this.snackBar.warning('Unable To make call.')
          }
        }
      })
      .catch(err => {
        this.snackBar.error('Unable To make call!')
      })
      .finally(() => {
        this.isApiCallInProgress = false
      })
  }

  addNumber() {
    const payload = {
      "leadId": this.data.leadId,
      "otherNumber": CryptoJS.SHA256('91' + this.otherNumber).toString(CryptoJS.enc.Hex)
    }
    this.http.postData(this.url.saveOtherNumber, payload)
      .then((res: any) => {
        if (res.status == "Success") {
        }
      })
      .catch(err => {
      })
  }

  getSrnNumberApiCall(category: string) {
    let srNumberUrl = this.url.srNumber;
    const reqObj: any = {
      category: category,
      id: localStorage.getItem('userId')
    }
    this.isApiCallInProgress = true
    if (this.data.doctorOnboarding) {
      srNumberUrl = srNumberUrl + '/medicalDashboard-doctors';
    } else {
      srNumberUrl = srNumberUrl + '/medicalDashboard';
    }
    this.http.getData(srNumberUrl)
      .then((res: any) => {
        if (res.status == "Success") {
          this.srnNumbersArr = res.result
        } else {
          this.snackBar.info('Unable to get CLI number !')
        }
      })
      .catch(err => {
        this.snackBar.error('Unable to get CLI number !')
      })
      .finally(() => {
        this.isApiCallInProgress = false
      })
  }

  closeModal() {
    if (this.isNumberUnmask) {
      this.dialogRef.close(this.unMaskNumbersArr)
      return
    }
    this.dialogRef.close()
  }

  sendMessage() {
    const riskReq = {
      'appointmentId': this.data.appointmentId
    };
    const url = this.url.sendMsgToPatient + `${this.data.leadId}/${this.selectedNumber == 'Other' && this.otherNumber ? this.otherNumber : this.selectedNumber}/${this.selectedCliNumber}`
    this.http.postData(url, riskReq).then((res: any) => {
      if (res.status == "Success") {
        this.snackBar.success('Message Sent Successfully')
        this.closeModal()
      } else {
        if (res.data.result.message) {
          this.snackBar.warning(res.data.result.message)
        } else {
          this.snackBar.warning('Unable To Send Message')
        }
      }
    })
      .catch(err => {
        this.snackBar.error('Unable To Send Message')
      })
  }
}
