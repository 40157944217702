import { Pipe, PipeTransform, NgModule } from "@angular/core";
import { AbstractControl, FormGroup, FormControl, FormArray } from "@angular/forms";


@Pipe({
  name: 'reactiveFormValue',
  pure:false
})
export class ReactiveFormValuePipe implements PipeTransform {

  transform(controls: AbstractControl | FormGroup | FormControl | FormArray, arg?: { index?: number, key?: string }): any {
    if (!controls) null    
    const value = controls.value
    if (Array.isArray(value)) {
      if (typeof(arg.index) === 'number') {
        if (typeof(value[arg.index]) !== 'object') {
          return value[arg.index]
        } else if (arg.key) {
          value[arg.index][arg.key]
        } else {
          return value[arg.index];
        }
      }
    } else if (typeof (value) === 'object') {
      if (arg.key) return value[arg.key]
      return null
    }else{
      return value
    }

    return null;
  }


}

