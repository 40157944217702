import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInResolver } from '../shared/resolvers/login.resolver';
import { HrContractComponent } from './hr-contract.component';

const routes: Routes = [
    {
        path: '',
        component: HrContractComponent,
        resolve: {
            user: SignInResolver
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class HrContractRoutingModule {}
