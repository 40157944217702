import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import * as Parse from 'parse';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

}

Parse.initialize(environment['appId']); // use your appID & your js key
(Parse as any).serverURL = environment['parseUrl']; // use your server url