import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConstantsService } from 'src/app/shared/services/constants.service';
import { HttpServiceService } from 'src/app/shared/services/http-service.service';
import { SnackBarService } from '../../services/snack-bar.service';

@Component({
  selector: 'app-show-number',
  templateUrl: './show-number.component.html',
  styleUrls: ['./show-number.component.scss']
})
export class ShowNumberComponent implements OnInit {

  otp:string=''
  unMaskRemark:string=''
  isApiCallInProgress:boolean = false
  reqobj:any
  constructor(
    public dialogRef: MatDialogRef<ShowNumberComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private http:HttpServiceService,
    private url:ConstantsService,
    private snackBar:SnackBarService
  ) { }

  ngOnInit() {
    console.log(this.data);

    if(this.data.doctorFlag) {
      this.reqobj = {
        'leadId': this.data.leadId,
        'source': this.data.source ? this.data.source : "MedicalDashboard",
        "doctorFlag": this.data.doctorFlag
    }
    } else {
      this.reqobj = {
        // "mobileNo": localStorage.getItem("otpMobile"),
        'leadId': this.data.leadId,
        'source': this.data.source ? this.data.source : "MedicalDashboard",
        // "role":   localStorage.getItem("role")
        "docLeadFlag": this.data.docLeadFlag
    }
    }

    this.requestandVerficationOtpCall(this.url.sendOtpMask,this.reqobj)
    .then((res:any)=>{
      if (res.status == "Success") {
        this.snackBar.success('OTP sent.')
      }else{
        this.snackBar.info('Unable To send OTP.')
      }
    })
    .catch(()=>{
      this.snackBar.error('Unable To Send OTP')
    })
    .finally(()=>{
      this.isApiCallInProgress=false
    })
  }

  closeModal(data?){
    this.dialogRef.close(data)
  }
  otpInputHandler(e){
    if (e && typeof(e)==='string'){
      if (e && e.length>1) return this.otp = this.otp.slice(0,-1)+e[e.length-1]
      return this.otp = this.otp+e
    } 
    return this.otp = this.otp.slice(0,-1)
  }

  submitOtp(){
    this.unMaskRemark = this.unMaskRemark.trim()
    if (!this.unMaskRemark) {
      this.snackBar.info('Please Enter Remark')
      return;
    }
    this.reqobj = {
      // "Mobile":localStorage.getItem("otpMobile"),
      "leadId": this.data.leadId,
      "otp":this.otp,
      "reason": this.unMaskRemark
    }
    this.requestandVerficationOtpCall(this.url.verifyOtp,this.reqobj)
    .then((res:any)=>{
      
      if (res.status == "Success") {
        if (res.description){
          this.snackBar.success(res.description)
        }else{
          this.snackBar.success('OTP Verify.')
        }
        this.closeModal([res.result])
      }else{
        if (res.description){
          this.snackBar.warning(res.description)
        }else{
          this.snackBar.warning('Unable To Verify OTP!')
        }
      }
    })
    .catch(err=>{
      this.snackBar.error('Unable To Verify OTP!')
    })
    .finally(()=>{
      this.isApiCallInProgress=false
    })
  }

  requestandVerficationOtpCall(url:string,reqObj:any){
    this.isApiCallInProgress=true
    return this.http.postData(url,reqObj)
  }
}
